// Basics
.swiper-header {
  width: 100%;
  min-height: 540px;
  background: $lighter-gray;
  @include ease(all);
}

.swiper-slide {
  display: flex;
	width: 100%;
	padding: 0;
  background: $lighter-gray;
  background-size: cover;
  background-position: center;
  @include ease(all);
  &.xl {
    min-height: 620px;
  }
  &.lg {
    min-height: 540px;
  }
  &.md {
    min-height: 360px;
  }
}

.swiper-slide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $dark-bg-6;
  z-index: 10;
}

.swiper-slide-content {
  position: absolute;
  margin: 0;
  left: 15px;
  bottom: 15px;
  z-index: 100;
  text-align: left;
  h6, small {
    color: $lighter-gray;
  }
}

.swiper-button-prev, .swiper-button-next {
  z-index: 100;
  color: $lighter-gray;
  @include ease(all);
  &:hover, &:active, &:focus {
    color: $white;
  }
}


// Dynamic
.swiper-static-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.swipe-header-content {
  padding: 15px 30px;
  max-width: 720px;
  h1, h2, h3, h4, h5 {
    color: $white;
    text-shadow: $dark-shadow-1;
  }
}