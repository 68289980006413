.map-block {
	border-radius: 0px;
	overflow: hidden;
	background: $lighter-gray;
	margin-bottom: 0;
	border: none;
	&.xl {
		height: 480px;
	}
	&.lg {
		height: 400px;
	}
	&.md {
		height: 320px;
	}
	&.sm {
		height: 240px
	}
	&.xs {
		height: 180px;
	}
}

.google-map, .map-placeholder {
	height: inherit;
	border-radius: 0px;
}

.map-placeholder {
	position: relative;
	background: $lighter-gray;
	img {
		width: 60px;
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		left: 0;
		right: 0;
		margin: auto;
	}
}

.map-info-block {
	min-width: 320px;
	padding: 0 15px;
	border-radius: 0px;
	text-align: left;
}