.admin-main {
	position: relative;
  padding: 15px 0;
	width: 100%;
	min-height: calc(100vh - 140px);
}

.auth-main {
	position: relative;
  padding: 0;
	width: 100%;
	background: $white;
	margin: 5em auto;
	min-height: calc(100vh - 200px);
}

.mkt-main {
	position: relative;
  padding: 0;
	width: 100%;
	min-height: calc(100vh - 540px);
}

.splash-main {
	position: relative;
  padding: 0;
	width: 100%;
	background: $white;
	min-height: calc(100vh - 200px);
}