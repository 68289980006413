.table-responsive {
	min-height: 420px;
}

table {
	width: 100%;
}


table.index {
	thead th {
		padding: 0px;
		border-top: none;
		border-bottom: 1px solid $dark-bg-1;
		font-size: 0.8em;
		vertical-align: middle;
		text-transform: uppercase;
		white-space: nowrap;
		button {
			padding: 12px 10px;
			font-size: inherit;
			font-weight: inherit;
			text-transform: inherit;
			color: inherit;
			border-radius: 0;
			@include ease(all);
			&:hover {
				color: $darker-gray;
				background: $light-gray;
			}
			&.active {
				color: $white;
				background: $secondary-color;
			}
		}
		&:last-child {
			text-align: center;
		}
	}
	tbody tr {
		border-radius: 0px;
		@include ease(all);
		&:hover {
			background: $light-gray;
		}
	}
	td {
		vertical-align: middle;
		font-size: 0.9em;
		padding: 20px 10px;
		color: $dark-gray;
		border-bottom: 1px solid $lighter-gray;
		&:first-child {
			// font-weight: $bold;
		}
		a {
			display: block;
			// padding: 15px 10px;
			font-weight: inherit;
			color: inherit;
		}
		&:last-child {
			text-align: center;
		}
	}
	td img.user {
		border-radius: 50%;
		width: 50px;
		height: 50px;
	}
}
