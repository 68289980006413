.card {
	width: 100%;
	border-radius: 4px;
	overflow: hidden;
	margin-bottom: 1.5em;
	@include ease(all);
}

.card.activity {}

.card.chat {
	padding: 0px;
	box-shadow: none;
	// border: none;
}

.card.detail {
	background: $white;
	box-shadow: $dark-shadow-05;
	border: 1px solid $dark-bg-1;
}

.card.info {
	border: none;
	background: $white;
}

.card.list {
	@extend .flex-row;
	&:hover {
		@extend .mid-shadow;
	}
}

.card.search {
	margin: 0;
	padding: 15px;
	border-radius: 0;
	border: none;
	@extend .bb;
	@extend .flex-row;
	@extend .flex;
	@include ease(all);
	&:hover {
		background: $lighter-gray;
		box-shadow: inset 4px 0 0 $primary-color;
	}
}

.card.select {
	margin: 0;
	border: none;
	border-radius: 0;
	@extend .bb;
	@extend .flex-row;
}

.card.table {
	padding: 15px 0;
	border: none;
	border-bottom: 1px solid $dark-bg-1;
}


// Header
.card-header {
	position: relative;
	padding: 0;
	overflow: hidden;
	border: none;
	border-radius: 0 !important;
	background: $primary-color;
}

.card-header.activity {
	padding: 15px 15px 0px 15px;
	background: $white;
}

.card-header.chat {
	padding: 15px;
	background: $white;
}

.card-header.detail {
	width: 100%;
	margin: auto;
}

.card-header.list {
	width: 20%;
	min-width: 80px;
	max-width: 120px;
	@include aspectRatio(1,1);
}

.card-header.map {
	width: 100%;
	margin: auto;
}

.card-header.search {
	width: 5%;
	min-width: 60px;
	border-radius: 4px !important;
	@include aspectRatio(1,1);
}

.card-icon-wrapper {
	@include aspectRatio(1,1);
}

.card-icon {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: auto;
	color: $white;
	@include transform(translateY(-50%));
}

.card-img {}


// Body
.card-body {
	padding: 1.2rem 1.25rem;
}

.card-body.activity {}

.card-body.list {
	width: 80%;
	padding: 0rem 1.25rem;
	@extend .flex-between;
}

.card-body.search {
	width: 85%;
	padding: 0 15px;
	@extend .flex-between;
}

.card-body.select {
	@extend .flex-between;
}


// Footer
.card-footer {
	width: 100%;
	padding: 15px;
	font-size: 80%;
	color: $gray;
	background: $white;
}

.card-footer.activity {}

.card-footer.grid {
	@extend .bt;
}

.card-footer.chat {
	padding: 15px;
}
