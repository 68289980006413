.admin-container {
  position: relative;
  left: 220px;
  width: calc(100vw - 220px);
  background: $lighter-gray;
  min-height: 100%;
  @include ease(all);
  @media all and (max-width: $medium) {
    left: 0;
    width: 100%;
    position: relative;
  }
}

.admin-container.collapsed {
  left: 80px;
  width: calc(100vw - 80px);
}