.input-group.clean {
  .input-group-append, .input-group-text {
    background: transparent;
    border: none;
    color: $gray;
  }
}

.input-group-prepend {
  border-right: none;
  .input-group-text {
    overflow: hidden;
  }
}

.input-group-append {
  border-left: none;
  .input-group-text {
    overflow: hidden;
  }
}

.input-group-text.btn {
}

textarea.clean {
  width: 100%;
}