.jumbotron {
  color: $white;
  background: $primary-color;
  border-radius: 0;
  @include ease(all);
  h1 {
    color: inherit;
    text-transform: uppercase;
  }
  h4 {
    color: inherit;
  }
}

.jumbotron-divider {
  height: 4px;
  width: 30%;
  background: $secondary-color;
}