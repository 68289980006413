.admin-announcement-block {
	position: relative;
	margin-top: -50px;
	opacity: 0;
	padding: 10px 15px;
	z-index: 10;
  left: 220px;
  width: calc(100vw - 220px);
	height: 50px;
	color: $white;
	background: $secondary-color;
	z-index: 100;
	@include ease(all);
	h6 {
		font-family: $sub-font;
		font-size: 14px;
		letter-spacing: 2px;
		text-transform: uppercase;
	}
	&:hover, &:active {
		color: $white;
		background: lighten($primary-color, 5%);
		cursor: pointer;
	}
  @media all and (max-width: $medium) {
    left: 0;
    width: 100%;
    position: relative;
  }
}