.fc-block {
	position: relative;
	&.detail {
		min-height: 680px;
		height: calc(100vh - 240px);
	}
	&.settings {
		min-height: 680px;
		height: calc(100vh - 320px);
	}
	&.wizard {
		min-height: 680px;
		height: calc(100vh - 320px);
	}
}

// Header
.fc-header-toolbar {
	margin-bottom: 1em !important;
	h2 {
		font-size: 20px !important;
		font-family: $primary-font;
		font-weight: $bold;
	}
	.fc-left {
		.fc-button {
			@extend .btn;
			@extend .btn-toggle-primary;
			@extend .btn-sm;
		}
		.fc-button-active {
			@extend .active;
		}
	}
	.fc-right {
		.fc-button {
			@extend .btn;
			@extend .btn-text-gray;
			&:disabled {
				opacity: 1;
				background: transparent;
				color: $light-gray;
			}
		}
		.fc-button-active {
			@extend .active;
		}
	}
}

// CALENDAR
.fc {
	table {
		border-color: $dark-bg-1 !important;
		background: $white;
		tbody {
			border: none;
			td {
				border-color: $dark-bg-1 !important;
				.fc-day-number {
					padding: 5px;
					font-size: 14px;
					color: $gray;
				}
			}
			thead td {
				border: none;
			}
		}
	}
}

.fc-head-container {
	background: transparent;
	thead, tr {
		border: none;
		th {
			text-transform: uppercase;
			border: none;
			border-right: 1px solid $dark-bg-1;
			background: transparent;
			height: 40px;
			font-size: 0.8em;
			letter-spacing: 2px;
			font-family: $primary-font;
			vertical-align: middle;
			&:last-child {
				border-right: none;
			}
		}
	}
}

thead .fc-today {
	span {
		color: $primary-color !important;
		background: $lighter-gray;
		display: block;
		margin: auto;
		line-height: 1.4em;
		white-space: nowrap;
	}
}

tbody {
	.fc-today {
		background: transparent !important;
	}
	thead .fc-today {
		background: none !important;
		background-color: none !important;
		border: none !important;
	}
}

td.fc-widget-header {
	border: none;
	border-top: 1px solid $dark-bg-1;
	table {
		border: none;
	}
}

.fc-scroller {
	overflow: hidden !important;
}

// TIMELNE
.fc-timeGrid-view {
	thead .fc-today {
		background: $lighter-gray !important;
	}
}

.fc-day-grid {
	border-bottom: 1px solid $dark-bg-1;
}

.fc-week {
	border: none !important;
}

.fc-axis {
	text-align: left !important;
 	span {
 		font-size: 0.8em;
 		text-transform: uppercase;
 		margin: auto;
 		font-weight: $m-bold;
 	}
}

.fc-divider {
	padding: 0 !important;
	border: none !important;
	border-bottom: 1px solid $lighter-gray !important;
}

.fc-day {

}


// Customizations
.fc-event {
	cursor: pointer;
}

.fc-booking {
	border: none !important;
	padding: 5px;
	.fc-time {
		color: $white;
		font-size: 14px;
	}
	.fc-content {
		white-space: inherit;
		border: none;
		.fc-title {
			line-height: 2em;
			color: $white;
			font-weight: $reg;
			font-size: 14px;
		}
	}
}

.fc-schedule-interval {
	border: none !important;
	.fc-time {
		color: $white;
		font-size: 14px;
		display: none;
	}
	.fc-content {
		white-space: inherit;
		border: none;
		.fc-title {
			line-height: 2em;
			color: $white;
			font-weight: $reg;
			font-size: 14px;
		}
	}
}

.fc-schedule-event {
	border: none !important;
	background: $dark-gray !important;
	cursor: pointer;
	.fc-time {
		color: $white;
		font-size: 14px;
		display: none;
	}
	.fc-content {
		white-space: inherit;
		border: none;
		.fc-title {
			float: left;
			line-height: 1.5rem;
			color: $white;
			font-weight: $reg;
			font-size: 14px;
		}
	}
}