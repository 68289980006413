.btn-doc {
	padding: 0;
	width: 100%;
	color: $gray;
	@include ease(all);
	&:hover {
		.file-block {
			background: darken($lighter-gray, 5%);
		}
	}
}

.doc-block {
	position: relative;
	width: 100%;
	min-height: 320px;
	background: $lighter-gray;
	border-radius: 0px;
	padding: 15px;
	color: $gray;
	border: 1px dashed $light-gray;
	@include ease(all);
}

.doc-block-inner {
	position: absolute;
	color: $gray;
	top: 50%;
	left: 0;
	right: 0;
	margin: auto;
	@include transform(translateY(-50%));
}