html, body {
	position: relative;
  font-family: $secondary-font;
  font-size: 16px;
  max-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: $white;
  // overflow: hidden;
}

body {
  // overflow-x: hidden;
  // overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;
}

#root {
	height: inherit;
}

#application {
	height: inherit;
}


img {
  max-width: 100%;
  height: auto;
}

label {
	font-size: 14px;
	font-weight: $bold;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 1px;
}

input {}

span {}

a {
	text-decoration: none;
	@include ease(all);
	&:hover {
		text-decoration: none;
	}
}

a.underline {
	&:hover {
		text-decoration: none;
	}
}

blockquote {
	font-size: 36px;
	font-weight: $light;
	font-style: italic;
	line-height: 1.7em;
	span {
		display: block;
		margin-top: 30px;
		line-height: 1em;
		font-weight: $bold;
		font-style: italic;
		color: $gray;
	}
}


h1, h2, h3, h4, h5, h6 {
	letter-spacing: 0.5px;
	margin: 0;
	font-family: $primary-font;
	line-height: 1.7em;
	color: $darker-gray;
	text-transform: none;
	&.p {
		margin-bottom: 1.7em;
	}
	svg {
		vertical-align: 0;
	}
}

h1 {
	letter-spacing: 1.5px;
	font-size: 4em;
	@media all and (max-width: $medium) {
		font-size: 2.5em;
	}
	@media all and (max-width: $small) {
		font-size: 1.8em;	
	}
}

h2 {
	letter-spacing: 1px;
	font-size: 2.4em;
	@media all and (max-width: $medium) {
		font-size: 2em;
	}
	@media all and (max-width: $small) {
		font-size: 1.8em;	
	}
}

h3 {
	font-size: 2em;
	@media all and (max-width: $medium) {
		font-size: 1.8em;
	}
	@media all and (max-width: $small) {
		font-size: 1.6em;	
	}
}

h4 {
	font-size: 1.4em;
	@media all and (max-width: $medium) {
		font-size: 1.3em;
	}
	@media all and (max-width: $small) {
		font-size: 1.2em;	
	}
}

h5 {
	font-family: $secondary-font;
	font-size: 1.2em;
	@media all and (max-width: $medium) {
		font-size: 1.1em;
	}
	@media all and (max-width: $small) {
		font-size: 1.1em;	
	}
}

h6 {
	font-family: $secondary-font;
	font-size: 1.0em;
	@media all and (max-width: $medium) {
		font-size: 1.0em;
	}
	@media all and (max-width: $small) {
		font-size: 1.0em;
	}
}

p {
	font-size: 1.0em;
	font-weight: $reg;
	@media all and (max-width: $medium) {
		font-size: 1.0em;
	}
	@media all and (max-width: $small) {
		font-size: 1.0em;	
	}
}

small {
	display: block;
	line-height: 1.5rem;
	font-size: 15px;
}