.card.stat {
	padding: 0px;
	margin-bottom: 15px;
}

.card-header.stat {
	padding: 0px 15px;
	color: $secondary-color;
	background: $white;
}

.card-body.stat {
	padding: 10px 15px;
}
