.cart-block {
	padding: 0;
	height: 100vh;
	width: 100%;
}

.cart-title {
	padding: 4em 1.5em;
	background: $primary-color;
	color: $white;
	h2, h3, h4, h5, h6 {
		color: inherit;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-weight: $bold;
	}
}

.cart-body {
	padding: 15px;
	overflow-y: scroll;
	height: calc(100vh - 240px);
	&.xl {
		min-height: 720px;
	}
	&.lg {
		min-height: 600px;
	}
	&.md {
		min-height: 480px;
	}
	&.sm {
		min-height: 360px;
	}
	&.xs {
		min-height: 240px;
	}
}

.cart-footer {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0px 15px 15px;
	min-height: 50px;
	background: $white;
	z-index: 5;
}