.avatar-wrapper {
	@extend .flex-between;
}

.avatar-block {
	padding: 0;
	@extend .flex-start;
}

.avatar-img {
	width: 50px;
	height: 50px;
	border-radius: 4px;
	position: relative;
	background: $gray;
	@extend .mr-3;
	&.xl {
		width: 120px;
		height: 120px;
	}
	&.lg {
		width: 80px;
		height: 80px;
	}
	&.md {
		width: 40px;
		height: 40px;
	}
	&.sm {
		width: 32px;
		height: 32px;
	}
	&.xs {
		width: 24px;
		height: 24px;
	}
}

.avatar-details {

}

.avatar-actions {

}
