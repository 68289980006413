.StripeElement {
  margin: 0px 0 60px;
  display: block;
  width: 100%;
  padding: 0.7rem 0.8rem;
  font-size: 1.7rem;
  line-height: 1.5;
  color: #495057;
  @extend .form-control;
  border-radius: 0px;
  font-family: $secondary-font;
  border: 1px solid $light-gray;
  &:focus {
    border: 1px solid $primary-color;
  }
}

.StripeElement.StripeElement--focus {
  color: #495057;
  background-color: $white;
  border: 1px solid $primary-color;
  outline: none;
  box-shadow: none;
  font-family: $secondary-font;
}

.StripeElement.StripeElement--invalid {
  border-color: 2px solid $danger;
}

.StripeElement.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}