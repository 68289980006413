.form-group {
  &.xl {
    padding: 60px 0;
  }
  &.lg {
    padding: 45px 0;
  }
  &.md {
    padding: 30px 0;
  }
  &.sm {
    padding: 15px 0;
  }
}

.form-control {
  font-size: 15px;
  letter-spacing: 0.5px;
  font-family: $secondary-font;
  font-weight: $reg;
  line-height: 1.6em;
  height: auto;
  text-align: left;
  @include placeholder($gray);
  &:active, &:focus {
    border: 1px solid $secondary-color;
    outline: none;
    box-shadow: none;
  }
}

.form-control {
  &.xl {
    font-size: 2.5em;
    font-weight: $light;
  }
  &.lg {
    font-size: 2em;
    font-weight: $light;
  }
  &.md {
    font-size: 1.2em;
  }
  &.sm {
    font-size: 0.9em;
  }
  &.xs {
    font-size: 0.8em;
    width: fit-content;
  }
  &[readonly] {
    background: $white;
  }
}

.form-control.clean {
  border: none;
  &:hover, &:active, &:focus {
    border: none;
  }
}

.form-label {
  font-weight: $m-bold;
  color: $darker-gray;
  text-transform: none;
}

.form-text {
  color: $gray;
  padding-bottom: 1em;
}

.form-footer {
	@extend .border-top;
	padding: 15px 0;
	margin: 0 15px;
}