.dropdown {}

.dropdown-toggle {
	&::after {
		display: none;
	}
}

.dropdown-toggle-user {
	padding: 10px;
	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: $lighter-gray;
	}
}

.dropdown-menu {
	margin: 0;
	padding: 0;
	width: 100%;
	height: inherit;
	border: none;
	background: transparent;
}

.dropdown-menu.show {
	// opacity: 1 !important;
	.dropdown-menu-inner {
		@extend .fade-in-down;
	}
}

.dropdown-menu-inner {
	max-height: 20em;
	overflow: hidden scroll;
	background: $white;
	border: 1px solid $light-gray;
	border-radius: 0.2rem;
	box-shadow: $dark-shadow-1;
	@include ease(all);
	&.date {
		max-height: fit-content;
	}
	&.search {
		min-height: 180px;
		max-height: 50vh;
		width: 100%;
	}
	&.user {
		margin-top: 10px;
		min-width: 18em;
		max-height: fit-content;
	}
}

.dropdown-divider {
	margin: 0;
}

.dropdown-item {
	padding: 0;
	width: 100%;
	outline: none;
	display: block;
	color: $gray;
	padding: 15px;
	font-size: 15px;
	border-radius: 0;
	width: 100%;
	text-align: left;
	cursor: pointer;
	@include ease(all);
	&:hover {
		outline: none;
		text-decoration: none;
		color: $darker-gray !important;
		background: $light-gray !important;
	}
	&:active, &.active {
		text-decoration: none;
		color: $darker-gray !important;
		background: $light-gray !important;
	}
	&:focus {
		outline: none;
	}
}

.dropdown-item {
	a.sub-font, button.sub-font {
		font-family: $sub-font;
		font-size: 0.8em;
		color: $gray;
	}
}