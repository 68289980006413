.mobile-nav-wrapper {}

.mobile-nav-header {
	padding: 15px;
}

.mobile-nav-hero {
	padding: 4em 15px;
	background: $primary-color;
	.title {
		color: $white;
	}
}

.mobile-nav-body {
	padding: 15px;
}

.mobile-nav-footer {
	padding: 15px 0;
	.nav-link {
		margin: 0;
		padding: 10px 15px;
		text-align: left;
		font-size: 15px;
		font-weight: $m-bold;
		width: 100%;
		color: $gray;
		font-family: $sub-font;
	}
}