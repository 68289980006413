.btn-photo {
	display: contents;
	padding: 0;
	width: auto;
	color: $gray;
	text-align: center;
	margin: auto;
	width: inherit;
	@include ease(all);
	&:hover {
		.file-block {
			background: darken($lighter-gray, 5%);
		}
	}
}

.photo-block {
	position: relative;
	color: $gray;
	padding: 0px;
	width: 140px;
	overflow: hidden;
	border-radius: 4px;
	border: 2px dashed $light-gray;
	background: $lighter-gray;
	cursor: pointer;
	@extend .flex-center;
	@include aspectRatio(1,1);
	@include ease(all);
	&.facility {
		border-radius: 4px;
		@include aspectRatio(1,1);
	}
	&.user {
		border-radius: 4px;
		@include aspectRatio(1,1);
	}
	&.photo {
		width: 50%;
		@include aspectRatio(16,9);
	}
}

.photo-img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	max-width: 100%;
	max-height: 100%;
}


.photo-block-inner {
	position: absolute;
	left: 0;
	right: 0;
	color: $gray;
	margin: auto;
	text-align: center;
	display: block;
	width: 100%;
}
