.time-picker-wrapper {
	margin: 15px 0;
	padding: 0 15px;
	max-height: 480px;
	overflow-y: scroll;
}


// Time picker input
button.input-group {
	padding: 0;
	border: none;	
	text-align: left;
}