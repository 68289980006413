@import "~bootstrap/scss/bootstrap";

// Utils
@import 'utils/variables';
@import 'utils/mixins';
@import 'utils/animations';
@import 'utils/btns';
@import 'utils/colors';
@import 'utils/fonts';
@import 'utils/helpers';


// Basics
@import 'basics/html';
@import 'basics/mains';
@import 'basics/containers';
@import 'basics/flows';
@import 'basics/footers';
@import 'basics/headers';
@import 'basics/mobile-nav';
@import 'basics/navs';
@import 'basics/routes';
@import 'basics/sections';
@import 'basics/sub-sections';


// Components
@import 'components/accordion';
@import 'components/alerts';
@import 'components/avatar';
@import 'components/badges';
@import 'components/banners';
@import 'components/breadcrumbs';
@import 'components/buttons';
@import 'components/cards';
@import 'components/carts';
@import 'components/charts';
@import 'components/dropdowns';
@import 'components/empties';
@import 'components/file-upload';
@import 'components/forms';
@import 'components/inputs';
@import 'components/lists';
@import 'components/loading';
@import 'components/modals';
@import 'components/pagination';
@import 'components/photo-upload';
@import 'components/progress';
@import 'components/search';
@import 'components/stats';
@import 'components/tables';
@import 'components/tags';
@import 'components/toasts';
@import 'components/tooltips';


// Admin
@import 'admin/admin-announcement';
@import 'admin/admin-container';
@import 'admin/admin-main';
@import 'admin/admin-nav';
@import 'admin/admin-sidenav';


// Vendor
// @import 'vendors/react-autosize';
@import 'vendors/burger-menu';
@import 'vendors/day-picker';
@import 'vendors/font-awesome';
@import 'vendors/full-calendar';
@import 'vendors/google-maps';
@import 'vendors/google-places';
@import 'vendors/hamburger';
@import 'vendors/react-star-rating';
@import 'vendors/time-picker';
@import 'vendors/skeleton';
@import 'vendors/stripe';
@import 'vendors/swiper';
@import 'vendors/switch';
@import 'vendors/vimeo-player';

