// Loading Section
.loading-main {
	position: relative;
  padding: 0;
	width: 100%;
	min-height: calc(100vh - 540px);
}

.loading-section {
	margin: 30px auto;
	text-align: center;
}


// Loading Takeover
.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	@include ease(all);
}

.loading-block {
	position: fixed;
	top: 50%;
	left: 0;
	right: 0;
	width: fit-content;
	height: fit-content;
	padding: 15px;
	margin: auto;
	text-align: center;
	border-radius: 0px;
	z-index: 10001;
	@include ease(all);
	@include transform(translateY(-50%));
}

.loading-block {
	background: $white;
	border-radius: 16px;
	min-width: 280px;
	@include ease(all);
	img {
		margin: auto;
		max-width: 60px;
		margin-bottom: 15px;
	}
}

// Loading
.loading-content {
	padding: 15px 0;
	h5 {
		font-weight: $bold;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
}