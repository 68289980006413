// Brand
.primary-bg {
	background: $primary-color !important;
}

.secondary-bg {
	background: $secondary-color !important;
}

.transparent-bg {
	background: transparent !important;
}

.success-bg {
	background: $success !important;
}

.warning-bg {
	background: $warning !important;
}

.danger-bg {
	background: $danger !important;
}

.black-bg {
	background: $black !important;
}

.darker-gray-bg {
	background: $darker-gray !important;
}

.dark-gray-bg {
	background: $dark-gray !important;
}

.gray-bg {
  background: $gray !important;
}

.light-gray-bg {
  background: $light-gray !important;
}

.lighter-gray-bg {
	background: $lighter-gray !important;
}

.white-bg {
	background: $white !important;
}


/****  TEXT ****/

// Brands
.primary-color {
  color: $primary-color !important;
}

.secondary-color {
  color: $secondary-color !important;
}

.success-color {
	color: $success !important;
}

.warning-color {
	color: $warning !important;
}

.danger-color {
	color: $danger !important;
}

.darker-gray-color {
	color: $darker-gray !important;
}

.dark-gray-color {
	color: $dark-gray !important;
}

.gray-color {
	color: $gray !important;	
}

.light-gray-color {
  color: $light-gray !important;
}

.lighter-gray-color {
  color: $lighter-gray !important;
}

.white-color {
	color: $white !important;
}