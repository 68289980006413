// Main
@mixin bootstrap-btn($color, $bg) {
  color: $color;
  background-color: $bg !important;
  border-color: $bg !important;
  &:hover {
    background: lighten($bg, 5%);
    border-color: lighten($bg, 5%);
    color: $color;
  }
  &:active, &.active {
    background-color: darken($bg, 10%) !important;
    border-color: darken($bg, 10%) !important;
    color: $color;
  }
  &:focus {
    background: $bg;
  }
  &.disabled {
    background: $bg;
  }
}

// Outline
@mixin bootstrap-outline-btn($color, $activeColor) {
  color: $color;
  background: transparent;
  border: 1px solid $color;
  &:hover {
    background: $color;
    border-color: darken($color, 5%);
    color: $white;
  }
  &:active, &.active {
    background: $activeColor;
    color: $white;
    border-color: $activeColor;
  }
  &.disabled {
    background: transparent;
  }
}


// Nav
@mixin bootstrap-nav-btn($color, $activeColor) {
  padding: 10px 15px;
  margin: 0;
  color: $gray;
  font-size: 18px;
  white-space: nowrap;
  svg {
    vertical-align: bottom;
    color: inherit;
  }
  &:hover {
    color: $activeColor;
  }
  &:active, &.active {
    color: $activeColor;
  }
  &.disabled {
    background: transparent;
  }
}


// Text
@mixin bootstrap-text-btn($color, $bg) {
  color: $color;
  background: $bg;
  padding-left: 0;
  padding-right: 0;
  svg {
    color: inherit;
  }
  &:hover {
    background: $bg;
    color: lighten($color, 10%);
  }
  &:active, &.active {
    background: $bg;
    color: darken($color, 10%);
  }
  &.disabled {
    background: transparent;
  }
}


@mixin bootstrap-list-btn($color, $activeColor) {
  color: $color;
  padding: 1em 0.5em;
  background: transparent;
  &:hover {
    color: darken($color, 15%);
  }
  &:active, &.active {
    color: $activeColor;
  }
}


// Toggle
@mixin toggle-btn($color, $bg) {
  color: $gray;
  background: $lighter-gray;
  &:hover {
    color: $dark-gray;
    background: darken($lighter-gray, 5%);
  }
  &:active, &.active {
    color: $color;
    background: $bg;
    &:hover {
      color: $color;
      background: $bg;
    }
  }
  &:focus {
    background: $lighter-gray;
  }
  &.disabled {
    background: $bg;
  }
}


@mixin toggle-outline-btn($color, $bg) {
  color: $gray;
  background: transparent;
  border: 1px solid $light-gray;
  &:hover {
    color: $dark-gray;
    background: $lighter-gray;
  }
  &:active, &.active {
    color: $color;
    background: $bg;
  }
  &.disabled {
    background: transparent;
  }
}