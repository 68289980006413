// SVGs
svg.svg-inline--fa {
  line-height: 1.5em;
  &.xl, &.fa-3x {
    font-size: 2em;
  }
  &.lg, &.fa-2x {
    font-size: 1.5em;
  }
  &.md, &.fa-1x {
    font-size: 1.0em;
  }
  &.sm {
    font-size: 0.75em;
  }
  &.xs {
    font-size: 0.5em;
  }
}
