.badge {
	padding: 1em;
	font-size: 0.7em;
	font-weight: $reg;
	letter-spacing: 1px;
	line-height: 0em;
	text-transform: uppercase;
	font-family: $sub-font;
	color: $white;
	background: $gray;
}


// Basics
.badge-primary {
	background: $primary-color;
	color: $white;
}

.badge-secondary {
	background: $secondary-color;
	color: $white;
}

.badge-warning {
	background: $warning;
	color: $white;
}

.badge-info {
	background: $dark-gray;
	color: $white;
}

.badge-danger {
	background: $danger;
	color: $white;
}


// Account
.badge-inactive {
	background: $light-gray;
	color: $white;
}

.badge-pending {
	background: $warning;
	color: $white;
}

.badge-unverified {
	background: $warning;
	color: $white;
}

.badge-verified {
	background: $primary-color;
	color: $white;
}

.badge-active {
	background: $primary-color;
	color: $white;
}

.badge-disabled {
	background: $danger;
	color: $white;
}


// Bookings
.badge-draft {
	background: $light-gray;
	color: $white;
}

.badge-requested {
	background: $gray;
	color: $white;
}

.badge-accepted {
	background: $primary-color;
	color: $white;
}

.badge-denied {
	background: $dark-gray;
	color: $white;
}

.badge-finished {
	background: $darker-gray;
	color: $white;
}

.badge-canceled {
	background: $danger;
	color: $white;
}

// Colors
.badge-gray {
	background: $gray;
	color: $white;
}

.badge-lighter-gray {
	background: $lighter-gray;
	color: $gray;
}
