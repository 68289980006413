.admin-sidenav {
  display: block;
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  padding: 0;
  width: 220px;
  z-index: 15;
  background: $primary-color;
  @include ease(all);
  overflow: hidden;
  @media all and (max-width: $medium) {
    display: none;
  }
}

.admin-sidenav.collapsed {
  width: 80px;
}

.admin-sidenav {
  .nav-link {
    padding: 15px;
    &:hover, &:active, &.active {
      color: $white;
      background: rgba($black, 0.2);
    }
    svg {
      margin-right: 15px;
    }
  }
}

.admin-sidenav.collapsed {
  .navbar-brand {
    padding: 0;
    margin: 0;
    img {
      width: 80px;
      margin: auto;
      padding: 5px;
      @include ease(all);
    }
  }
  .nav-link {
    text-align: center;
    svg {
      margin: 0;
    }
    span {
      display: none;
    }
  }
}

.admin-sidenav-header {}

.admin-sidenav-main {
  padding: 15px 0;
}

.admin-sidenav {
  .navbar-brand {
    padding: 15px 0;
    margin: 0;
    @extend .border-bottom;
  }
}

.admin-sidenav-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid $light-bg-1;
}