.accordion {
	padding: 30px 0;
	margin: 0;
	@extend .bb;
}

.accordion-toggle {
	@extend .flex-between;
}

.accordion-collapse {
	padding: 15px 0 0 0;
}