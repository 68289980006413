// Primary
$primary-color: #1C314F; // Blue
$secondary-color: #8F7848; // Gold

// Other
$success: #5CDBBD;
$warning: #95ADB6;
$danger: #D4353F;

// Links
$blue: #007bff;


// Grayscale Colors
$black: #080708;
$darker-gray: #1F2333;
$dark-gray: #2D3142;
$gray: #9EA7B3;
$mid-gray: #C5CFD6;
$light-gray: #F3F5F7;
$lighter-gray: #F6F8F9;
$white: #FFFFFF;


// Partner Colors
$twitter: #2E97E9;
$facebook: #0974E8;
$google: #db3236;
$linkedin: #3B5998;


// Shadows
$dark-shadow-05: 0 2px 4px 0 rgba($black, 0.05);
$dark-shadow-1: 0 4px 8px 0 rgba($black, 0.1);
$dark-shadow-2: 0 5px 10px 0 rgba($black, 0.2);


// Fonts
$primary-font: 'Open Sans Condensed', 'Helvetica', serif;
$secondary-font: 'Open Sans', 'Helvetica', sans-serif;
$accent-font: $secondary-font;
$sub-font: $secondary-font;


// Backgrounds
$dark-bg-05: rgba($black, 0.05);
$dark-bg-1: rgba($black, 0.1);
$dark-bg-2: rgba($black, 0.2);
$dark-bg-3: rgba($black, 0.3);
$dark-bg-4: rgba($black, 0.4);
$dark-bg-5: rgba($black, 0.5);
$dark-bg-6: rgba($black, 0.6);
$dark-bg-7: rgba($black, 0.7);
$dark-bg-8: rgba($black, 0.8);
$dark-bg-9: rgba($black, 0.9);

$light-bg-05: rgba($white, 0.05);
$light-bg-1: rgba($white, 0.1);
$light-bg-2: rgba($white, 0.2);
$light-bg-3: rgba($white, 0.3);
$light-bg-4: rgba($white, 0.4);
$light-bg-5: rgba($white, 0.5);
$light-bg-6: rgba($white, 0.6);
$light-bg-7: rgba($white, 0.7);
$light-bg-8: rgba($white, 0.8);
$light-bg-9: rgba($white, 0.9);


// Weights
$light: 300;
$reg: 400;
$m-bold: 600;
$bold: 700;


// Breakpoints
$xlarge: 1440px;
$large: 1260px;
$medium: 992px;
$small: 767px;
$xsmall: 480px;