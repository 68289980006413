.pagination {
	margin-bottom: 0;
}

.page-item {
	&:hover {

	}
	&:focus {
		outline: none;
	}
	.page-link {
		border: 1px solid $light-gray;
		background: transparent;
		border-radius: 0 !important;
		color: $gray;
		@include ease(all);
		&:hover {
			color: $dark-gray;
			background: $lighter-gray;
		}
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
}

.page-item.disabled {
	.page-link {
		border: 1px solid $light-gray;
		color: $gray;
		background: transparent;
	}
}

.page-item.active {
	.page-link {
		border: 1px solid $secondary-color;
		color: $white;
		background: $secondary-color;
		&:hover {
			color: $white;
		}
	}
}