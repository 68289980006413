.video-player-wrapper {
	position: relative;
	width: 100%;
}

.vimeo-player {
	position: relative;
	iframe {
		width: 100%;
	}
}