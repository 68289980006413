.flow-wrapper {
	padding: 30px 0px;
}

.flow-progress-wrapper {
		padding: 15px 0 45px;
}

.flow-block {
	@extend .section-block;
}