.subsection-block {
	margin-bottom: 15px;
}

.subsection-header {
	padding: 15px 15px 5px 15px;
}

.subsection-body {
	padding: 5px 15px;
}