// Navbar
.navbar {
	min-height: 80px;
	width: 100%;
	max-width: 100%;
	border-bottom: 1px solid $dark-bg-1;
	background: $white;
	@media all and(max-width: $medium) {
		min-height: 60px;
	}
}

.navbar-brand img {
	margin-right: 15px;
	max-width: 200px;
	@media all and(max-width: $medium) {
		max-width: 140px;
	}
}

.navbar-nav {
	flex-direction: initial;
	@media all and(max-width: $medium) {
		display: none;
	}
}

.nav-link {
	color: $gray;
	padding: 10px;
	font-size: 16px;
	font-weight: $reg;
	svg {
		color: inherit;
	}
	&.xl {
		font-size: 20px;
	}
	&.lg {
		font-size: 18px;
	}
	&.sm {
		font-size: 14px;
	}
	&:hover, &.active {
		color: $primary-color;
		background: $lighter-gray;
	}
	&.primary {
		color: $primary-color;
		&:hover, &:active, &.active {
			color: $primary-color;
			background: $lighter-gray;
		}
	}
}

.navbar-toggler {
	color: $gray;
}

.navbar .search-form {
	@media all and (max-width: $medium) {
		display: none;
	}
}

// Admin
.navbar.admin {
	.navbar-brand {
		display: none;
		@media all and(max-width: $medium) {
			display: block;
		}
	}
}