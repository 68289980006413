.modal-backdrop.show {
	opacity: 0.9;
	background: $darker-gray;
}

.modal {
	overflow: hidden;
}

.modal-dialog {
	margin: 5rem auto;
	border: none;
	position: relative;
}

.modal-content {
	border: none;
}

.modal-header {

}

.modal-body {
	&.xl {
		min-height: 540px;
	}
	&.lg {
		min-height: 360px;
	}
	&.md {
		min-height: 270px;
	}
	&.sm {
		min-height: 180px;
	}
	&.search {
		max-height: 500px;
		padding-left: 0;
		padding-right: 0;
		overflow: hidden scroll;
	}
}

.modal-footer {
	* {
		margin: 0;
	}
}

// Fullpage
.modal.fullpage {
	width: 100%;
	height: 100%;
	max-width: 100%;
	padding: 1em;
	margin: auto;
	background: $white;
	position: fixed;
	overflow: scroll;
	.modal-content {
		border: none;
		overflow: scroll;
	}
}
