$hamburger-layer-color: $gray;

// NPM
@import 'hamburgers/_sass/hamburgers/hamburgers';

.hamburger {
	display: none;
	@media all and(max-width: $medium) {
		display: flex;
	}
}

.hamburger-box {
	width: 32px;
}

.hamburger-inner {
	width: 32px;
	&:before {
		width: 32px;
	}
	&:after {
		width: 32px;
	}
}