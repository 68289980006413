.pac-container {
	z-index: 10000;
	background: $white;
	border-radius: 0px;
	box-shadow: $dark-shadow-1;
	margin: 0;
	padding: 0;
	min-width: 10rem;
	@extend .border;
	&::after {
		padding: 10px 0;
		margin: 0px 15px;
		min-height: 40px;
	}
}
.pac-item {
	font-family: $secondary-font;
	font-weight: $reg;
	font-size: 15px;
	padding: 10px 15px;
	width: 100%;
	text-align: left;
	color: $gray;
	cursor: pointer;
	border: none;
	border-bottom: 1px solid $lighter-gray;
	@include ease(all);
	&:hover, &:active, &.active {
		background: $dark-bg-1;
	}
}
.pac-item {
	@extend .flex-start;
}

.pac-icon {
	margin: 0;
	margin-right: 10px;
}

.pac-item-query {
	padding-right: 10px;
	font-size: 15px;
	font-weight: $bold;
}

.pac-matched {
}
