// Misc
.bm-burger-button {
  display: none;
}


// Don't modify
.bm-menu-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  max-width: 480px !important;
  z-index: 11000000000 !important;
}

// Menu
.bm-menu {
  top: 0;
  background: $white;
  padding: 0;
}

.bm-item {
  &:focus {
    outline: none;
  }
}

.bm-morph-shape {
  fill: $white;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
  display: none;
}

.bm-cross {
  background: $gray;
}

.bm-overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: $dark-bg-8 !important;
}