.tooltip-wrapper {
	position: relative;
}

.tooltip {
	border-radius: 4px;
	background: $darker-gray;
}

.tooltip.tooltip-inner {
	display: none;
	opacity: 0;
}

.tooltip.show {
	display: block;
	opacity: 1 !important;
}

.tooltip-inner {
	display: block;
	font-size: 16px;
	padding: 0.5em 1em;
	font-family: $secondary-font;
	letter-spacing: 0.5px;
	border-radius: 4px;
	background: $darker-gray;
	@include ease(all);
}

.bs-tooltip-right .arrow {
	left: -0.4em;
	&::before {
		border-right-color: $darker-gray;
	}
}
