.skeleton {}

.skeleton-img {
	line-height: inherit !important;
	border-radius: 0 !important;
	@include aspectRatio(1, 1);
}

.card.list .skeleton-img {
	position: absolute !important;
}

.card.map .skeleton-img {
	position: absolute !important;
}

.card.search .skeleton-img {
	position: absolute !important;
	border-radius: 4px !important;
}