// Mkt
.mkt-footer {
	padding: 80px 0;
	min-height: 320px;
	.footer-links .btn {
		padding-left: 0;
		padding-right: 0;
	}
}

.mkt-footer-logo {
	max-width: 180px;
	margin-bottom: 15px;
}

.mkt-footer-social {
	margin: 5px 0 15px;
}


// App
.footer-block {
  width: 100%;
  min-height: 80px;
  padding: 15px 0;
}

.footer-inner {
	@extend .flex-start;
	@media all and (max-width: $medium) {
		display: block;
	}
}

.footer-logo {
	position: relative;
	width: 180px;
	@media all and (max-width: $medium) {
		margin-bottom: 15px;
		width: 140px;
	}
}

.footer-links {
	.btn {
		padding-left: 0;
		padding-right: 1em;
		font-size: 14px;
		display: block;
		text-align: left;
	}
}
