.tags-container {
	z-index: 1000;
	@extend .flex-start;
}

.tag {
	padding: 4px 10px;
	background: $light-gray;
	margin-right: 5px;
	border-radius: 4px;
	z-index: 1000;
	@extend .flex-between;
}

.tag-body {
	padding: 0;
	font-size: 80%;
	@extend .flex-start;
}

.tag-img {
	padding: 0;
	margin-right: 15px;
}

.tag-details {
	padding: 0;
}

.tag-action {
	display: flex;
	padding-left: 8px;
	font-size: 70%;
}