.alert {
	border: none;
}

.alert-primary {
	color: $white;
	background-color: $primary-color;
	border-color: darken($primary-color, 5%);
}

.alert-secondary {
	color: $white;
	background-color: $secondary-color;
	border-color: darken($secondary-color, 5%);
}

.alert-success {
	color: $white;
	background-color: $success;
	border-color: darken($success, 5%);
}

.alert-danger {
	color: $white;
	background-color: $danger;
	border-color: darken($danger, 5%);
}