.empty-block {
	width: 100%;
	padding: 15px;
	max-width: 460px;
	text-align: center;
	display: block;
	margin: auto;
	.img {
		margin-bottom: 15px;
	}
	h5 {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: $bold;
	}
}

.empty-block.route {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	@include transform(translateY(-50%));
}

.empty-block.table {
	@extend .empty-block.route;
}

.empty-block.grid {
	@extend .empty-block.route;
}

.empty-block.list {
	@extend .empty-block.route;
}

.empty-block.section {
	margin: 60px auto 60px;
}

.empty-block.select {
	@extend .empty-block.route;
}

.empty-block.preview {
	margin: 30px auto 30px;
}
