.list-group {
	.list-group-item {
		padding: 0;
		text-transform: uppercase;
		margin-right: 0 !important;
		letter-spacing: 2px;
		font-size: 14px;
		border: 0;
	}
	@media all and (max-width: $medium) {
		svg {
			display: none;
		}
	}
	@media all and (max-width: $small) {
		display: none;
	}
}