.search-wrapper {
	position: relative;
	width: 100%;
	&.nav {
		@media all and (max-width: $medium) {
			display: none;
		}
	}
}

input.search {
	&::-webkit-search-cancel-button {
  	display: none;
	}
}

.search-block {
	padding: 0;
	border-bottom: 1px solid $lighter-gray;
}

.search-header {
	padding: 5px 15px;
	background: $light-gray;
	small {
		// color: $white;
	}
}

.search-body {
	margin: 0;
	padding: 0 15px;
	width: 100%;
	border: none;
}