.banner-block {
	position: fixed;
	padding: 20px 15px;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 100;
	visibility: hidden;
	@include ease(all);
	&.visible {
		@extend .fade-in-up;
		visibility: visible;
	}
}

.banner-content {
	h3, h4, h5, h6 {
		color: $white;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin-bottom: 10px;
	}
	p {
		color: $white;
		letter-spacing: 1px;
		font-weight: 300;
		font-size: 15px;
		margin: 0;
	}
}

.banner-btns {
	@extend .flex-end;
}