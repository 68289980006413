.progress-wrapper {
	display: block;
	width: 100%;
	background: $white;
	@media all and(max-width: $small) {
		display: none;
	}
}

.progress-wrapper {
	.list-group-item {
		padding: 0;
		margin-right: 5px;
		border: none;
		width: auto;
		span {
			color: $gray;
		  border-radius: 0px;
		  padding: 1em 0.5em;
		  background: transparent;
		  cursor: default;
		  &.active {
		    color: $primary-color;
		  }
		}
		&:last-child {
			&::after {
				color: $light-gray;
				content: none;
			}
		}
	}
}


// Bar
.progress {
	width: 100%;
	border-radius: 24px;
	height: 0.5em;
	background: rgba($black, 0.05);
}

.progress-bar {
	background: $primary-color;
}



// Bar Version
.progress-bar-wrapper {
	padding-bottom: 30px;
	display: none;
	@media all and(max-width: $small) {
		display: block;
	}
}