.breadcrumb {
	padding: 20px 15px;
	background: transparent;
	border-radius: 0;
	width: 100%;
	margin: 0;
	background: $white;
	@extend .light-shadow;
	@extend .border-bottom;
}

.breadcrumb-item { 
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 14px;
	font-family: $sub-font;
}