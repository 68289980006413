.DayPicker {
	width: 100%;
}

.DayPicker-wrapper {
	// margin-top: 1em;
	width: 100%;
	&:focus {
		outline: none;
	}
}

.DayPicker-NavBar {
	position: absolute;
	right: 0;
}

.DayPicker-NavButton {
	position: static;
	&.DayPicker-NavButton--prev {
		margin-right: 1em;
	}
}

.DayPicker-Caption {
	// margin-bottom: 1em;
	padding: 0;
	& > div {
		font-weight: $bold;
		// position: absolute;
	}
}


.DayPicker-Months, .DayPicker-Month {
	width: 100%;
	margin: 0;
}

.DayPicker-Week {
	margin: 5px;
}

.DayPicker-Day {
	border-radius: 0px;
	line-height: 2em;
	border: 1px solid $light-gray;
	@include ease(all);
	&:hover {
		color: $darker-gray !important;
		background: $lighter-gray !important;
	}
	&:focus {
		outline: none;
	}
}

.DayPicker-Day--today {
	background: $light-gray !important;
	color: $darker-gray;
	font-weight: $reg;
}

.DayPicker-Day--selected {
	background: $primary-color !important;
	color: $white;
	border: 1px solid $primary-color;
	font-weight: $reg;
	&:hover {
		color: $white !important;
		background: $primary-color !important;
	}
}

// Input
.DayPickerInput {
	display: block !important;
}